import styled from 'styled-components';

import Layout from '@/layout';

import Hero from './components/Hero';
import Banners from './components/Banners';
import Advantages from './components/Advantages';
import WorkSteps from './components/WorkSteps';
import AboutSection from './components/AboutSection';
import VerifiedForm from './components/VerifiedForm';
import Faq from './components/Faq';

function VerifiedAtlantmLanding() {
  return (
    <Layout>
      <Component>
        <Hero />
        <Banners />
        <Advantages />
        <WorkSteps />
        <AboutSection />
        <VerifiedForm />
        <Faq />
      </Component>
    </Layout>
  );
}

export default VerifiedAtlantmLanding;

const Component = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.white};
`;
