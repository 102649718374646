import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { HideScrollbar } from '@/components/styles';

import Step from './components/Step';
import { StepsProps } from './types';

function Steps({ steps, columns = 6 }: StepsProps) {
  return (
    <Component columns={columns}>
      {steps?.map((step, index) => (
        <StepWrapper key={`step-${index}`}>
          <Step {...step} counter={index + 1} />
        </StepWrapper>
      ))}
    </Component>
  );
}

export default Steps;

const Component = styled.div<{ columns: number }>`
  display: grid;
  gap: 30px;
  overflow: auto;
  grid-template-columns: repeat(${({ columns }) => columns}, 1fr);

  ${media.tablet(css`
    justify-content: space-between;
    gap: 7px;
  `)}

  ${HideScrollbar};
`;

const StepWrapper = styled.div`
  ${media.tablet(css`
    min-width: 160px;
  `)}
`;
