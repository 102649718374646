import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

import AdvantageItem from './components/AdvantageItem';
import { AdvantagesListProps } from './types';

function AdvantagesList({
  advantages,
  title,
  columns = 5,
}: AdvantagesListProps) {
  return (
    <Component>
      <Title>{title}</Title>
      <List columns={columns}>
        {advantages?.map(({ text, image }, index) => (
          <AdvantageItem key={`${index}-advantage`} text={text} image={image} />
        ))}
      </List>
    </Component>
  );
}

export default AdvantagesList;

const Component = styled.div`
  background: ${colors.yellowGradient};
  padding: 30px;
  border-radius: 20px;

  ${media.tablet(css`
    padding: 30px 20px;
  `)}
`;

const List = styled.div<{ columns: number }>`
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
  gap: 20px;

  ${media.tablet(css`
    grid-template-columns: 1fr;
    gap: 10px;
  `)}
`;

const Title = styled.h2`
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  margin-bottom: 20px;
  color: ${colors.black};

  ${media.tablet(css`
    font-size: 24px;
    line-height: 28px;
  `)}
`;
