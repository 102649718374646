import React from 'react';
import styled from 'styled-components';

import Link from '@/components/Link';
import { colors } from '@/constants/theme';
import BreadcrumbsArrow from '@/assets/svg/breadcrumbs-arr.svg';

import { BreadcrumbsProps } from './types';

function Breadcrumbs({ links }: BreadcrumbsProps) {
  if (!links || links.length === 0) {
    return null;
  }

  return (
    <Component>
      {links.map(({ label, link }, index) => (
        <React.Fragment key={index}>
          <LinkItem to={link} currentLink={index === links.length - 1}>
            {label}
          </LinkItem>
          {index + 1 !== links.length && (
            <SvgWrapper>
              <BreadcrumbsArrow />
            </SvgWrapper>
          )}
        </React.Fragment>
      ))}
    </Component>
  );
}

export default Breadcrumbs;

const Component = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 18px;
`;

const LinkItem = styled(Link)<{ currentLink?: boolean }>`
  font-size: 12px;
  line-height: 14px;
  white-space: nowrap;
  color: ${colors.gray1500};

  &:hover {
    color: ${colors.main};
  }
`;

const SvgWrapper = styled.div`
  display: flex;
  width: 10px;
  height: 10px;

  svg {
    width: 100%;
    height: 100%;
  }
`;
