import React from 'react';
import styled from 'styled-components';

import Layout from '@/layout';

import Hero from './components/Hero';
import Faq from './components/Faq';
import AboutSection from './components/AboutSection';
import Advantages from './components/Advantages';
import Partners from './components/Partners';
import StepsList from './components/StepsList/StepsList';
import CarBrands from './components/CarBrands';

function EmergencyManager() {
  return (
    <Layout>
      <Component>
        <Hero />
        <AboutSection />
        <Advantages />
        <Partners />
        <StepsList />
        <CarBrands />
        <Faq />
      </Component>
    </Layout>
  );
}

export default EmergencyManager;

const Component = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.white};
`;
