import React from 'react';
import styled, { css } from 'styled-components';

import { CssSnippet } from '@tager/web-components';

import { colors } from '@/constants/theme';

import { ButtonProps, ButtonSize, ButtonVariant } from './types';

export function Button({
  icon,
  children,
  variant,
  size,
  ...props
}: ButtonProps) {
  return (
    <StyledButton variant={variant} size={size} {...props}>
      {icon && <Icon>{icon}</Icon>}
      {children}
    </StyledButton>
  );
}

const getVariantStyle = (): Record<ButtonVariant, CssSnippet> => {
  return {
    contained: css`
      color: ${colors.white};
      background: ${colors.purpleGradient400};
      background-size: 200%;

      &:hover {
        background-position: 100% 200%;
      }
    `,
    contained100: css`
      color: ${colors.black};
      background: ${colors.white};

      &:hover {
        background: ${colors.gray200};
      }
    `,
    outlined: css`
      color: ${colors.black};
      background: ${colors.white};
      border: 1px solid ${colors.gray};

      &:hover {
        background: ${colors.gray300};
      }
    `,
  };
};

const getSizeStyle = (): Record<ButtonSize, CssSnippet> => {
  return {
    small: css`
      height: 40px;
      padding: 10px;
      font-size: 14px;
      line-height: 17px;
    `,
    big: css`
      height: 50px;
      padding: 15px;
      font-size: 16px;
      line-height: 19px;
    `,
  };
};

const buttonStyle = css<{ variant: ButtonVariant; size: ButtonSize }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-weight: 500;
  border-radius: 10px;
  text-align: center;

  ${({ variant, size }) => css`
    ${getVariantStyle()[variant]}
    ${getSizeStyle()[size]}
  `}
`;

const StyledButton = styled.button<{
  variant: ButtonVariant;
  size: ButtonSize;
}>`
  ${buttonStyle}
`;

const Icon = styled.div`
  flex: 0 0 20px;
  width: 20px;
  height: 20px;
  margin-right: 5px;

  svg {
    width: 100%;
    height: 100%;
  }
`;
