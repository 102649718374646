import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import Picture from '@/components/Picture';

import { Advantage } from './types';

function AdvantageItem({ text, image }: Advantage) {
  return (
    <Component>
      <Image
        loading="lazy"
        desktop={convertThumbnailToPictureImage(image)}
        alt={'advantage-image'}
      />
      <Text>{text}</Text>
    </Component>
  );
}

export default AdvantageItem;

const Component = styled.div`
  background: ${colors.white};
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  min-height: 145px;
  border-radius: 20px;

  ${media.tablet(css`
    flex-direction: row;
    gap: 10px;
    padding: 10px 20px 10px 5px;
    min-height: initial;
  `)}
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: ${colors.black};

  ${media.tablet(css`
    text-align: initial;
  `)}
`;

const Image = styled(Picture)`
  width: 80px;
  height: 80px;
  border-radius: 20px;

  ${media.tablet(css`
    width: 60px;
    height: 60px;
    margin-bottom: auto;
  `)}

  picture,
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  picture {
    ${media.tablet(css`
      width: 60px;
      height: 60px;
    `)}
  }
`;
