import styled from 'styled-components';

import Tick from './components/Tick';
import { TicksProps } from './types';

function Ticks({ ticks }: TicksProps) {
  return (
    <Component>
      <List>
        {ticks.map(
          ({ text }, index) =>
            text && <Tick key={`${index}-tick`} text={text}></Tick>
        )}
      </List>
    </Component>
  );
}

export default Ticks;

const Component = styled.div``;

const List = styled.ul`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 11px;
`;
