import React, { useState } from 'react';
import { Swiper } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import styled, { css } from 'styled-components';
import { uniqueId } from 'lodash';

import { useUpdateEffect } from '@tager/web-core';

import { media } from '@/utils/mixin';
import ArrowIcon from '@/assets/svg/brands-arrow.svg';
import { colors } from '@/constants/theme';

import { SliderWrapperProps } from './types';

SwiperCore.use([Navigation]);

function SliderWrapper({ id, options, children }: SliderWrapperProps) {
  const [key, setKey] = useState('');
  const navPrevClassname = `swiper-prev${id ?? ''}`;
  const navNextClassname = `swiper-next${id ?? ''}`;

  useUpdateEffect(() => {
    setKey(uniqueId());
  }, [options]);

  return (
    <Component>
      <LeftSliderArrow className={navPrevClassname}>
        <LeftSvgWrapper>
          <ArrowIcon />
        </LeftSvgWrapper>
      </LeftSliderArrow>
      <RightSliderArrow className={navNextClassname}>
        <RightSvgWrapper>
          <ArrowIcon />
        </RightSvgWrapper>
      </RightSliderArrow>
      <Swiper
        key={key}
        spaceBetween={options?.spaceBetween ?? 0}
        slidesPerView={options?.slidesPerView || 'auto'}
        speed={600}
        navigation={{
          prevEl: `.${navPrevClassname}`,
          nextEl: `.${navNextClassname}`,
        }}
      >
        {children}
      </Swiper>
    </Component>
  );
}

export default SliderWrapper;

const Component = styled.div`
  position: relative;
`;

const SvgWrapper = () => css`
  width: 20px;
  height: 20px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const LeftSvgWrapper = styled.div`
  ${SvgWrapper};
  transform: rotate(-180deg);
`;

const RightSvgWrapper = styled.div`
  ${SvgWrapper};
`;

const SliderArrow = () => css`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 8px;
  z-index: 3;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: solid 1px ${colors.gray1300};
  background: ${colors.white};
  box-shadow: 0px 5px 10px 0px ${colors.gray1700};

  &:hover {
    box-shadow: 0px 10px 15px 0px ${colors.gray1700};
  }

  ${media.tablet(css`
    display: none;
  `)}
`;

const LeftSliderArrow = styled.button`
  ${SliderArrow}

  left: -20px;
`;

const RightSliderArrow = styled.button`
  ${SliderArrow}

  right: -20px;
`;
